<template>
  <div class="test-request-form page">
    <div class="header-container add-border-bottom">
      <div class="banner">
        <img src="../../assets/images/testRequestForm/header-bg.png" alt="" />
      </div>
      <div class="step-container">
        <van-row
          type="flex"
          justify="center"
          align="center"
          class="step-icon-row"
        >
          <van-col span="6">
            <span class="step-icon" :class="{ active: step === 1 }">1</span>
          </van-col>
          <van-col span="12">
            <van-divider
              ><span class="step-icon" :class="{ active: step === 2 }"
                >2</span
              ></van-divider
            >
          </van-col>
          <van-col span="6"
            ><span class="step-icon" :class="{ active: step === 3 }"
              >3</span
            ></van-col
          >
        </van-row>
        <van-row type="flex" justify="center" class="step-message-row">
          <van-col span="6">录入信息</van-col>
          <van-col span="12">确认信息</van-col>
          <van-col span="6">录入完成</van-col>
        </van-row>
      </div>
    </div>
    <div class="success-container">
      <img
        src="../../assets/images/testRequestForm/submit-success.png"
        alt=""
        class="icon"
      />
      <div class="tips">信息创建成功</div>
    </div>
    <div class="success-container">
      <img
        src="../../assets/images/testRequestForm/wx-program.jpg"
        alt=""
        class="wx-icon"
      />
      <div class="title">裕知患者端</div>
      <div class="description">后续您可以进行电子签名及查看基因检测报告。</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      step: 3,
    };
  },
};
</script>
<style lang="less" scoped>
.test-request-form {
  .header-container {
    .banner {
      width: 100%;
      height: 356px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .step-container {
      text-align: center;
      padding-bottom: 33px;
      color: @color-26;
      font-size: @font-size-medium;
      .step-icon-row {
        .step-icon {
          display: inline-block;
          width: 40px;
          height: 40px;
          line-height: 40px;
          background: #e1e1e1;
          border-radius: 50%;
          color: @color-26;
          &.active {
            background: @theme-color;
            color: white;
          }
        }
      }
      .step-message-row {
      }
    }
  }
  .add-border-bottom {
    border-bottom: 15px solid #f8f8f8 !important;
  }
  .success-container {
    text-align: center;
    color: @color-26;
    .icon {
      // width: 156px;
      // height: 156px;
      width: 80px;
      height: 80px;
      display: inline-block;
      margin-top: 50px;
      margin-bottom: 50px;
    }
    .tips {
      font-size: @font-size-large;
      font-weight: 600;
    }

    .wx-icon {
      margin: 50px 0 50px;
      width: 250px;
      height: 250px;
    }

    .title {
      font-size: 28px;
    }

    .description {
      padding-top: 20px;
      color: #c5c5c5;
      font-size: 28px;
    }
  }
  
}
</style>